import React, { useCallback, useState } from 'react';
import PartyPopperIcon from '../../images/party-icon.svg';
import CallToAction from '../../molecules/CallToAction/CallToAction';
import Image from '../../utils/OptimizedImage';
import FormInput from '../shared/FormControl/Input';

const GatedForm = ({ onViewReport }) => {
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const handleFirstName = useCallback(
    ({ target }) => setFirstName(target.value),
    []
  );
  const handleLastName = useCallback(
    ({ target }) => setLastName(target.value),
    []
  );
  const handleEmail = useCallback(({ target }) => setEmail(target.value), []);
  const handlePhone = useCallback(({ target }) => setPhone(target.value), []);

  const handleViewReports = useCallback(() => {
    setSubmitting(true);
    if (!(firstName && lastName && email && phone)) {
      setError('Please complete all the required fields');
    } else {
      setError('');
      onViewReport(firstName, lastName, email, phone);
    }
  }, [email, firstName, lastName, onViewReport, phone]);

  return (
    <div className="gated-form">
      <Image src={PartyPopperIcon} alt="success" />
      <div className="title-wrapper">
        <p className="form-title">ROI Calculator Completed!</p>
        <p className="sub-title">
          Submit your name and info to view your results
        </p>
      </div>

      <div className="form-content">
        <div className="input-row">
          <div className="input-wrapper">
            <label htmlFor="firstName">First name</label>
            <FormInput
              required
              id="firstName"
              name="firstName"
              placeholder="First name"
              error={
                !firstName && submitting && 'First name field is required!'
              }
              value={firstName}
              onChange={handleFirstName}
            />
          </div>

          <div className="input-wrapper">
            <label htmlFor="lastName">Last name</label>
            <FormInput
              required
              id="lastName"
              name="lastName"
              placeholder="Last name"
              error={!lastName && submitting && 'Last name field is required!'}
              value={lastName}
              onChange={handleLastName}
            />
          </div>
        </div>

        <div className="input-row">
          <div className="input-wrapper">
            <label htmlFor="email">Email address</label>
            <FormInput
              required
              id="email"
              name="email"
              placeholder="Email address"
              type="email"
              error={!email && submitting && 'Email field is required!'}
              value={email}
              onChange={handleEmail}
            />
          </div>

          <div className="input-wrapper">
            <label htmlFor="phone">Phone number</label>
            <FormInput
              required
              id="phone"
              name="phone"
              placeholder="Phone number"
              error={!phone && submitting && 'Phone number field is required!'}
              value={phone}
              onChange={handlePhone}
            />
          </div>
        </div>

        {error && <p className="text-danger fs-16">{error}</p>}

        <CallToAction
          variant="secondary"
          size="lg"
          className="w-100 mb-3"
          value="View Reports"
          customHandleClick={handleViewReports}
        />
      </div>
    </div>
  );
};

export default GatedForm;
